<template>
  <app-list-view
    server-side
    app="equity"
    model="equity"
    api-url="equity/equity/"
    :title="$t('menu.equity')"
    :createTo="{ name: 'equityCreate' }"
    :editTo="{ name: 'equityEdit' }"
    :headers="headers"
  />
</template>

<script>
import AppListView from '@components/AppListView'

export default {
  name: 'equityCategoryList',
  components: {
    AppListView
  },
  computed: {
    headers() {
      return [
        { text: this.$t('fields.company'), value: 'company.name' },
        { text: this.$t('fields.equityName'), value: 'name' },
        {
          text: this.$t('fields.equityCategory'),
          value: 'equity_category.name'
        },
        { text: this.$t('fields.equityType'), value: 'equity_type.name' },
        {
          text: this.$t('fields.equityGroup'),
          value: 'equity_group.name'
        },
        { text: this.$t('fields.date'), value: 'date', isDate: true },
        { text: this.$t('fields.state'), value: 'state' },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          groupable: false,
          hideFilter: true
        }
      ]
    }
  },
  created() {
    this.$store.dispatch('addBreadcrumb', {
      name: 'equity'
    })
  }
}
</script>
